import Axios from 'axios';

let segmentLoaded = false;

//Segment callback does not execute reliably in some browsers' incognito modes, so we use this timeout to prevent blocking
const SEGMENT_CALLBACK_TIMEOUT = 1500;

export function loadSegment() {
  if (segmentLoaded || localStorage.getItem('skipSegment') === 'true') {
    return;
  }
  if (window.analytics) {
    Axios<{ segmentApiKey: string }>('/authenticate/segmentapikey').then(({ data }) => {
      window.analytics.load(data.segmentApiKey);
      segmentLoaded = true;
    });
  }
}

export function analyticsTrack(eventName: string, data?: { [name: string]: any }) {
  return new Promise((resolve, reject) => {
    try {      
      if (!window?.analytics?.identify) {
        return resolve({});
      }
        
      // eslint-disable-next-line prefer-const
      const segmentTimeout = setTimeout(() => {
        resolve({});
      }, SEGMENT_CALLBACK_TIMEOUT);

      const d = data || {};
      d.source = data.source ? data.source : 'Web';
      window.analytics.track(eventName, d, () => { 
        clearTimeout(segmentTimeout);
        resolve({eventName, ...(data ?? {}) }) }
      );      
    } catch (err) {
      // ignore
      console.error(err);
      reject();
    }
  });
}

export const analyticsIdentify = (userId, data) => {
  return new Promise((resolve, reject) => {
    try {
      if (!window?.analytics?.identify) {
        return resolve({});
      }
        
      // eslint-disable-next-line prefer-const
      const segmentTimeout = setTimeout(() => {
        resolve({});
      }, SEGMENT_CALLBACK_TIMEOUT);
      
      if (userId) {
        window.analytics.identify(userId, data,  
          () => {
            clearTimeout(segmentTimeout);
            resolve({userId, ...(data ?? {}) }) 
          });
      } else {
        //track anonymous
        window.analytics.identify(data, null,  () =>  { 
          clearTimeout(segmentTimeout);
          resolve({...(data ?? {}) }) 
        });
      }
    } catch (err) {
      // ignore
      console.error(err);
      reject();
    }
  });
}