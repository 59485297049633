import './SSOConnect.scss';
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Icon, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { loadSegment } from '../utility/segment';
import getQueryVariable from '../utility/getQueryVariable';
import Background from '../Background';

export function SSOConnect() {
  const emailAddress = getQueryVariable('emailAddress');
  const providerId = getQueryVariable('providerId');
  const denyUrl = getQueryVariable('denyUrl');
  const [showLoading, setShowLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  function getProviderDisplayText() {
    if (!providerId || providerId.length <= 1) {
      return providerId;
    }

    const lowerVariant = providerId.toLowerCase();
    return lowerVariant.charAt(0).toUpperCase() + lowerVariant.slice(1);
  }

  function getProviderIconClass() {
    return `icon-${providerId?.toLowerCase()}`;
  }

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('SSOTitle');
    loadSegment();
    window.analytics.page('SSO Connect');
  }, []);

  const onSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    setShowLoading(true);
    setErrorMessage(null);

    try {
      //currently all external providers are oauth
      const { data } = await Axios('/authenticate/oauthsignupconnect', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          emailAddress
        }
      });

      if (data) {
        window.location = data.redirectUrl;
      }
    } catch (err) {
      setErrorMessage(err.response.data?.message);
      setShowLoading(false);
    }
  };

  return (
    <div className="sso-connect">
      <Background />
      <div className="content">
        <div className="logo outside-card" onClick={() => window.location.assign('https://www.goformz.com')} />
        <div className="card">
          <div className="header">
            <span>{t('SSOConnectHeader')}</span>
          </div>

          {errorMessage ? <div className="error-state">{errorMessage}</div> : null}

          <div className="sso-explanation">
            <div className="found-text">
              <span>{t('SSOFoundExistingAccount')}</span>
            </div>

            <div className="provider-details">
              <span className="email-display">{emailAddress}</span>
              <div className="provider-display">
                <Icon className={`icon provider-icon ${getProviderIconClass()}`} />
                <span>{getProviderDisplayText()}</span>
              </div>
            </div>

            <div className="connect-details">
              <span>{t('SSOWouldYouConnect', { ssoprovider: getProviderDisplayText() })}</span>
            </div>
          </div>

          <div className="connect-action">
            <Button onClick={(e) => onSubmit(e)} loading={showLoading} className="ui primary button">
              {t('SSOAcceptConnect')}
            </Button>
          </div>
        </div>
        <div className="deny-footer-link">
          <a href={denyUrl || '/login'}>{t('SSODenyConnect')}</a>
        </div>
      </div>
    </div>
  );
}
