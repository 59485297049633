import React from 'react';
import { Icon, Button } from 'semantic-ui-react';
import { EXTERNAL_PROVIDER_RETURN_URL_KEY } from '../../constants/LocalStorageConstants';
import './ExternalProviders.scss';

export type Providers = 'google' | 'microsoft' | 'apple' | 'goformz';

export type ExternalProvidersProps = {
  showOrLine?: boolean;
  text?: string;
  activeProviders?: Providers[];
  onProviderClick: (provider: Providers) => void;
};

export default function ExternalProviders(props: ExternalProvidersProps) {
  const getProviders = (): Providers[] => {
    return ['google', 'microsoft', 'apple'].filter(
      (provider) => !props.activeProviders || props.activeProviders.some((activeProvider) => activeProvider.toLowerCase() === provider)
    ) as Providers[];
  };

  const onProviderClick = (provider: Providers) => {
    //lil hack to allow our error pages to redirect back to the calling page in the case that the
    //user does something like cancelling auth mid process
    localStorage.setItem(EXTERNAL_PROVIDER_RETURN_URL_KEY, window.location.href);
    return props.onProviderClick(provider);
  };

  const prettify = (provider: Providers) => {
    return provider.charAt(0).toUpperCase() + provider.slice(1);
  };

  return (
    <div className="external-providers">
      {!props.showOrLine || (
        <div className="provider-divider">
          <div className="line"></div>
          <h2>Or</h2>
          <div className="line"></div>
        </div>
      )}
      <div className="external-login-button-group">
        {getProviders().map((provider) => (
          <Button className="external-login-button" key={provider} onClick={() => onProviderClick(provider)}>
            <Icon className={`icon provider-icon icon-${provider}`} />
            <span className={`buttonText buttonText-${provider}`}>
              {props.text} {prettify(provider)}
            </span>
          </Button>
        ))}
      </div>
    </div>
  );
}
