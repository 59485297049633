import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import useQuery from '../../hooks/useQuery';
import ErrorPage from '../error/ErrorPage';
import { loadSegment } from '../utility/segment';
import './Logout.scss';
import { useHistory } from 'react-router-dom';
import Background from '../Background';

export function Logout() {
  const history = useHistory();
  const [showLoggedOutScreen, setShowLoggedOutScreen] = useState(false);
  const [showSignoutPrompt, setShowSignoutPrompt] = useState(false);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [errorMessage, setShowErrorMessage] = useState();
  const query = useQuery();
  const logoutId = query.get('logoutId');
  const { t } = useTranslation();

  useEffect(() => {
    if (!logoutId) {
      history.replace('/login');
    }
    loadSegment();
    logout(false);
  }, []);

  const logout = async (userPromptedOk: boolean) => {
    try {
      const url = `/authenticate/logout?logoutId=${logoutId}&userPromptedOk=${userPromptedOk}`;
      const { data } = await Axios(url, {
        // @ts-ignore FIX - not part of axios
        credentials: 'include'
      });

      if (data.showSignoutPrompt && !userPromptedOk) {
        setShowSignoutPrompt(true);
      } else {
        if (data.signOutIFrameUrl) {
          addLogoutIframe(data.signOutIFrameUrl);
        }
        if (data.postLogoutRedirectUri) {
          window.location = data.postLogoutRedirectUri;
        } else if (userPromptedOk) {
          setShowLoggedOutScreen(true);
        }
      }
    } catch (err) {
      setShowErrorPage(true);
      setShowErrorMessage(err.response.data.message);
    }
  };

  const formSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    logout(true);
  };

  let body = null;

  if (showErrorPage) {
    return <ErrorPage errorMessage={errorMessage} />;
  }

  if (showLoggedOutScreen) {
    body = (
      <header style={{ flexDirection: 'column' }}>
        <h5 className="logout-text">{t('LogoutSuccess')}</h5>
      </header>
    );
  } else if (showSignoutPrompt) {
    body = (
      <header style={{ flexDirection: 'column' }}>
        <h5 className="logout-text">{t('LogoutConfirmation')}</h5>
        <form onSubmit={formSubmit}>
          <input type="hidden" name="logoutId" value="TODO - logout id value here" />
          <fieldset>
            <div className="form-group">
              <Button className="primary-button" type="submit">
                {t('Yes')}
              </Button>
            </div>
          </fieldset>
        </form>
      </header>
    );
  }
  if (body) {
    return (
      <div className="logout">
        <Background />
        <div className="logo-wrap">
          <div className="logo" />
        </div>
        <div className="signup-block">
          <div className="form-card">{body}</div>
        </div>
        <div className="bottom-wrap" />
      </div>
    );
  }
  return (
    <div className="logout">
      <div>{t('LogoutInProgress')}</div>
    </div>
  );
}

function addLogoutIframe(url: string) {
  const iframe = document.createElement('iframe');
  iframe.width = '0';
  iframe.height = '0';
  iframe.className = 'signout';
  iframe.src = url;
  document.body.appendChild(iframe);
}
