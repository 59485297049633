import './Background.scss';

import React from 'react';
import cornerWaveSvg from './assets/corner-wave.svg';

export default function Background() {
  return (
    <div className="background">
      <img className="corner-wave" src={cornerWaveSvg} title="GoFormz corner wave image" />
    </div>
  );
}
