import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { loadSegment } from '../utility/segment';
import getQueryVariable from '../utility/getQueryVariable';
import PasswordInput from '../PasswordInput';
import './ResetPassword.scss';
import Loader from '../utility/Loader';
import Background from '../Background';

export function ResetPassword() {
  const returnUrl = getQueryVariable('returnUrl');
  const email = getQueryVariable('email');
  const expires = getQueryVariable('expires');
  const login = !!getQueryVariable('login');
  const token = getQueryVariable('token');
  const errorFromQuery = getQueryVariable('error');
  const passwordExpired = getQueryVariable('expired');

  const [showLoading, setShowLoading] = useState(true);
  const [showSubmitting, setShowSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorFromQuery || null);
  const [password, setPassword] = useState<string>();
  const [validPassword, setValidPassword] = useState(false);

  const { t } = useTranslation();

  const title = passwordExpired ? t('PasswordExpired') : t('ResetPasswordHeader');

  useEffect(() => {
    document.title = t('LoginTitle');
    loadSegment();
    window.analytics.page('resetpassword');
    validateLink();
  }, []);

  const validateLink = async () => {
    try {
      await Axios('/authenticate/validations/emailtoken', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          email,
          expires,
          token
        }
      });

      setShowLoading(false);
    } catch (error) {
      const title = t('ContactAdministrator');
      const message = error?.response?.data?.message || t('UnknownError');
      window.location.assign(`/error?title=${title}&message=${message}`);
    }
  };

  const onPasswordChange = (value: string, isValid: boolean) => {
    setPassword(value);
    setValidPassword(isValid);
  };

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!validPassword) {
      return;
    }

    setShowSubmitting(true);

    try {
      const response = await Axios('/authenticate/resetpassword', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          username: email,
          expires,
          token,
          newPassword: password,
          login,
          returnUrl
        }
      });

        if (response && response.data && response.data.redirectUrl) {
            // you're an IU in multiple accounts, take you to the account selection page
            if (!!response.data.selectAccount) {
                window.location.replace(`/accounts?ReturnUrl=${encodeURIComponent(response.data.redirectUrl)}`);
                return;
            }

        // you have one client or are not an IU
        window.location = response.data.redirectUrl;
      }
      setShowSubmitting(false);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || t('UnknownError'));
      setShowSubmitting(false);
    }
  };

  if (showLoading) {
    return <Loader isLoading />;
  }

  return (
    <div className="resetpassword">
      <Background />
      <div className="resetpassword content">
        <div className="top-row header">
          <div className="logo" />
          <div className="logo-wrap-large">
            <div className="logo" />
          </div>
        </div>
        <div className="form-card">
          <header style={{ flexDirection: 'column' }}>
            <h1 className="resetpassword-text">{title}</h1>
            {errorMessage ? <div className="error-state">{errorMessage}</div> : null}
            <h5 className="resetpassword-subtext">{t('ResetPasswordInstructions')}</h5>
          </header>
          <form name="resetpassword" onSubmit={onSubmit} className="reset-form">
            <div className="form-group">
              <PasswordInput
                placeholder={t('PasswordFieldPlaceholder')}
                inputClassName="form-control"
                className="password"
                onChange={onPasswordChange}
                useConfirmPassword={true}
                autofocus={true}
              />
              <div>
                <Button className="primary-button" id="resetpassword" type="submit" loading={showSubmitting} disabled={!validPassword}>
                  {t('SubmitButton')}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="bottom-wrap" />
    </div>
  );
}
